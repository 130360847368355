<template>
  <b-form
    @submit.prevent="$emit('submit', model)"
    @keydown.enter="$emit('submit', model)"
  >
    <b-row>
      <b-col sm="12">
        <b-form-group>
          <label>
            Fecha de registro entre
          </label>
          <b-row no-gutters>
            <b-col cols="6">
              <b-form-datepicker
                :value="model.date_min"
                class="margin-right-datepicker"
                :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                size="sm"
                reset-button
                :hide-header="true"
                placeholder="Desde"
                :label-help="null"
                :max="model.date_max"
                @input="update('date_min', $event)"
                start-weekday="1"
              />
            </b-col>
            <b-col cols="6">
              <b-form-datepicker
                :value="model.date_max"
                class="margin-left-datepicker"
                :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                size="sm"
                reset-button
                :hide-header="true"
                placeholder="Hasta"
                :label-help="null"
                :min="model.date_min"
                @input="update('date_max', $event)"
                start-weekday="1"
              />
            </b-col>
          </b-row>
        </b-form-group>
      </b-col>
      <b-col sm="12">
        <b-form-group
          label="Nombre"
          label-for="client-name"
        >
          <b-form-input
            id="client-name"
            :value="model.name"
            name="name"
            placeholder="Nombre"
            @input="update('name', $event)"
          />
        </b-form-group>
      </b-col>
      <b-col sm="12">
        <b-form-group
          label="Código"
          label-for="offer-code"
        >
          <b-form-input
            id="offer-code"
            :value="model.code"
            name="code"
            placeholder="Código"
            @input="update('code', $event)"
          />
        </b-form-group>
      </b-col>
      <b-col sm="12">
        <b-form-group
          label="Usuario/Comercial"
          label-for="offer-user"
        >
          <BaseSelect
            id="offer-user"
            :value="model.user"
            :resource="$data.$constants.RESOURCES.RESOURCE_USERS"
            @input="update('user', $event)"
          />
        </b-form-group>
      </b-col>
      <b-col sm="12">
        <b-form-group
          label="Pais"
          label-for="offer-country"
        >
          <BaseSelect
            id="offer-country"
            :value="model.country"
            :resource="$data.$constants.RESOURCES.RESOURCE_COUNTRIES"
            http-method="get"
            @input="handleCountryInput"
          />
        </b-form-group>
      </b-col>
      <b-col sm="12">
        <b-form-group
          label="Provincia"
          label-for="offer-province"
        >
          <BaseSelect
            id="offer-province"
            ref="offer-province-select"
            :value="model.province"
            :resource="$data.$constants.RESOURCES.RESOURCE_PROVINCES"
            @input="update('province', $event)"
          />
        </b-form-group>
      </b-col>
      <b-col sm="12">
        <b-form-group
          label="Cliente"
          label-for="offer-client"
        >
          <BaseSelect
            id="offer-client"
            :value="model.client"
            :resource="$data.$constants.RESOURCES.RESOURCE_CLIENTS"
            @input="update('client', $event)"
            :multiple="true"
            :reduce="((item) => {return { id: item.id, name: item.name }})"
          />
        </b-form-group>
      </b-col>
      <b-col sm="12">
        <b-form-group
          label="Obra"
          label-for="offer-project"
        >
          <BaseSelect
            id="offer-project"
            :value="model.project"
            :resource="$data.$constants.RESOURCES.RESOURCE_PROJECTS"
            @input="update('project', $event)"
          />
        </b-form-group>
      </b-col>
      <b-col sm="12">
        <b-form-group
          label="División"
          label-for="offer-division"
        >
          <BaseSelect
            id="offer-division"
            :value="model.division"
            :resource="$data.$constants.RESOURCES.RESOURCE_DIVISIONS"
            http-method="get"
            @input="update('division', $event)"
            :reduce="((item) => {return { id: item.id, name: item.name }})"
            :multiple="true"
          />
        </b-form-group>
      </b-col>
      <b-col sm="12">
        <b-form-group
          label="Estado"
          label-for="offer-status"
        >
          <StatusSelect
            id="offer-status"
            :value="model.status"
            :type="statusType"
            @input="update('status', $event)"
          />
        </b-form-group>
      </b-col>
    </b-row>
  </b-form>
</template>

<script>
import FormMixin from '@/mixins/formMixin'
import { OFFER_STATUS_TYPE } from '@/api/status-api'
import StatusSelect from '@/components/status/select/StatusSelect.vue'
import BaseSelect from '@/components/ui/select/BaseSelect.vue'

export default {
  name: 'OfferListFiltersForm',
  components: {
    BaseSelect,
    StatusSelect,
  },
  mixins: [FormMixin],
  data() {
    return {
      statusType: OFFER_STATUS_TYPE,
    }
  },
  methods: {
    handleCountryInput(country) {
      this.update('country', country)
      this.$refs['offer-province-select'].loadData({ country })
    },
  },
}
</script>

<style scoped>
.margin-left-datepicker {
  margin-left: 5px;
}
.margin-right-datepicker {
  margin-right: 5px;
}
</style>
